<template>
  <div class="search-sidebar--dropdown">
    <div
      v-b-toggle.collapseDistrict
      class="d-flex"
      style="justify-content: space-between;"
      @click="open"
    >
      <h3 class="filter--title">{{ $t('directory.search.subtitle') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <b-collapse visible id="collapseDistrict">
      <div class="dropdown-item" v-for="(district, key) in districts" :key="key">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            :id="'district' + key"
            v-model="selectedDistrict"
            :value="district.id"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" :for="'district' + key">
            <span>{{ district.name }}</span>
          </label>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    isOpened: true,
  }),
  computed: {
    ...mapState({
      districts: state => state.v2.directory.districts,
      isLoading: state => state.v2.search.isLoading,
    }),
    selectedDistrict: {
      get() {
        let selectedDistrict = this.$store.state.v2.directory.selectedDistrict;
        return selectedDistrict ? selectedDistrict.id : null;
      },
      set(val) {
        this.$store.dispatch('v2/directory/setSelectedDistrictById', val);
      },
    },
  },
  methods: {
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>

<style lang="scss" scoped>
.custom-control-label {
  span {
    white-space: normal;
  }
}
</style>
